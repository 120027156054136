// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Career_careerBlock__ygES9 {
  display: flex;
  flex-direction: column;
}

.Career_jobs__IWcm2 {
  height: 100%;
  width: 95vw;
}

.Career_careerBlock__ygES9 button {
  border: none;
  text-decoration: none;
  background-color: #6070FF;
  text-align: center;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  transition: 0.2s;
  width: min(200px, 90vw);
}

.Career_organizationalChart__gxshF {
  width: min(1000px, 100%);
}

.Career_applyButton__i\\+Aqs {
  width: min-content;
}`, "",{"version":3,"sources":["webpack://./src/pages/Career/Career.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".careerBlock {\n  display: flex;\n  flex-direction: column;\n}\n\n.jobs {\n  height: 100%;\n  width: 95vw;\n}\n\n.careerBlock button {\n  border: none;\n  text-decoration: none;\n  background-color: #6070FF;\n  text-align: center;\n  border-radius: 10px;\n  padding: 10px 15px;\n  cursor: pointer;\n  transition: 0.2s;\n  width: min(200px, 90vw);\n}\n\n.organizationalChart {\n  width: min(1000px, 100%);\n}\n\n.applyButton {\n  width: min-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"careerBlock": `Career_careerBlock__ygES9`,
	"jobs": `Career_jobs__IWcm2`,
	"organizationalChart": `Career_organizationalChart__gxshF`,
	"applyButton": `Career_applyButton__i+Aqs`
};
export default ___CSS_LOADER_EXPORT___;
