// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchCard_card__MWsyi {
    position: relative;
    display: flex;
    flex-direction: column;
}

.ResearchCard_container__8X6Fi {
    max-width: 100%;
    height: 200px;
    margin-bottom: 15px;
    overflow: hidden;
}

.ResearchCard_container__8X6Fi img {
    width: 100%;
    aspect-ratio: 1 / 1;  /* defining the aspect ratio of the image */
    object-fit: cover;    /* making sure the image isn't distorted */;
    max-height: 100%;
}

.ResearchCard_card__MWsyi:hover .ResearchCard_title__ln2Ax {
    text-decoration: underline;
}

.ResearchCard_card__MWsyi img {
    transition: 0.2s;
}

.ResearchCard_card__MWsyi:hover img {
    filter: brightness(75%);
    transform: scale(1.1);
}

.ResearchCard_link__274NO {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ResearchCard_credits__R3lm1 {
    font-size: 14px;
    color: rgb(93, 93, 93);
}

.ResearchCard_date__yCr7R {
    font-size: 14px;
    color: gray; 
}

.ResearchCard_date__yCr7R {
    margin: 2px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/ResearchPreview/ResearchCard/ResearchCard.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB,GAAG,2CAA2C;IACjE,iBAAiB,KAAK,0CAA0C;IAChE,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,UAAU;IACV,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".card {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n}\n\n.container {\n    max-width: 100%;\n    height: 200px;\n    margin-bottom: 15px;\n    overflow: hidden;\n}\n\n.container img {\n    width: 100%;\n    aspect-ratio: 1 / 1;  /* defining the aspect ratio of the image */\n    object-fit: cover;    /* making sure the image isn't distorted */;\n    max-height: 100%;\n}\n\n.card:hover .title {\n    text-decoration: underline;\n}\n\n.card img {\n    transition: 0.2s;\n}\n\n.card:hover img {\n    filter: brightness(75%);\n    transform: scale(1.1);\n}\n\n.link {\n    cursor: pointer;\n    z-index: 2;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}\n\n.credits {\n    font-size: 14px;\n    color: rgb(93, 93, 93);\n}\n\n.date {\n    font-size: 14px;\n    color: gray; \n}\n\n.date {\n    margin: 2px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ResearchCard_card__MWsyi`,
	"container": `ResearchCard_container__8X6Fi`,
	"title": `ResearchCard_title__ln2Ax`,
	"link": `ResearchCard_link__274NO`,
	"credits": `ResearchCard_credits__R3lm1`,
	"date": `ResearchCard_date__yCr7R`
};
export default ___CSS_LOADER_EXPORT___;
