// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1100px) {
    .NumberedLayout_iconContainer__dk2Bw {
        width: 100%;
        margin: 50px;
    }

    .NumberedLayout_iconParent__59Vcu {
        grid-template-columns: repeat(auto-fit, 375px);
    }
}

@media (max-width: 1100px) {
    .NumberedLayout_iconContainer__dk2Bw {
        margin: 20px;
    }

    .NumberedLayout_iconParent__59Vcu {
        grid-template-columns: repeat(auto-fit, min(250px, 90vw));
    }
}

.NumberedLayout_iconContainer__dk2Bw {
    flex-grow: 1;
}

.NumberedLayout_iconParent__59Vcu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    grid-template-rows: auto;
    grid-gap: 15px;
}

.NumberedLayout_element__2-MBJ {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
}

.NumberedLayout_number__K8GXe {
    flex-shrink: 0;
	background-color: var(--primary);
	color: white;
	border-radius: 50%;
	margin: 0;
	padding: 0;
	height: 25px;
	width: 25px;
	text-align: center;
	margin-right: 20px;
	margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/NumberedLayout/NumberedLayout.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,8CAA8C;IAClD;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,yDAAyD;IAC7D;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,wBAAwB;IACxB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,cAAc;CACjB,gCAAgC;CAChC,YAAY;CACZ,kBAAkB;CAClB,SAAS;CACT,UAAU;CACV,YAAY;CACZ,WAAW;CACX,kBAAkB;CAClB,kBAAkB;CAClB,eAAe;AAChB","sourcesContent":["@media (min-width: 1100px) {\n    .iconContainer {\n        width: 100%;\n        margin: 50px;\n    }\n\n    .iconParent {\n        grid-template-columns: repeat(auto-fit, 375px);\n    }\n}\n\n@media (max-width: 1100px) {\n    .iconContainer {\n        margin: 20px;\n    }\n\n    .iconParent {\n        grid-template-columns: repeat(auto-fit, min(250px, 90vw));\n    }\n}\n\n.iconContainer {\n    flex-grow: 1;\n}\n\n.iconParent {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: left;\n    grid-template-rows: auto;\n    grid-gap: 15px;\n}\n\n.element {\n    display: flex;\n    flex-direction: row;\n    flex-shrink: 0;\n}\n\n.number {\n    flex-shrink: 0;\n\tbackground-color: var(--primary);\n\tcolor: white;\n\tborder-radius: 50%;\n\tmargin: 0;\n\tpadding: 0;\n\theight: 25px;\n\twidth: 25px;\n\ttext-align: center;\n\tmargin-right: 20px;\n\tmargin-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": `NumberedLayout_iconContainer__dk2Bw`,
	"iconParent": `NumberedLayout_iconParent__59Vcu`,
	"element": `NumberedLayout_element__2-MBJ`,
	"number": `NumberedLayout_number__K8GXe`
};
export default ___CSS_LOADER_EXPORT___;
