// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchPreview_parent__lVfYN {
    width: 100%;
}

.ResearchPreview_grid__PN4ZK {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: flex-start;
    
    grid-template-rows: auto;
}

@media (max-width: 480px) {
    .ResearchPreview_grid__PN4ZK {
        grid-template-columns: 1fr;
        grid-gap: 5px;
    }
}

@media (max-width: 1100px) and (min-width: 480px) {
    .ResearchPreview_grid__PN4ZK {
        grid-template-columns: repeat(auto-fit, min(300px, 90vw));
        grid-gap: 10px;
    }
}

@media (min-width: 1100px) {
    .ResearchPreview_grid__PN4ZK {
        grid-template-columns: repeat(auto-fit, min(400px, 90vw));
        grid-gap: 15px;
    }
} `, "",{"version":3,"sources":["webpack://./src/components/ResearchPreview/ResearchPreview.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,uBAAuB;;IAEvB,wBAAwB;AAC5B;;AAEA;IACI;QACI,0BAA0B;QAC1B,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,yDAAyD;QACzD,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,yDAAyD;QACzD,cAAc;IAClB;AACJ","sourcesContent":[".parent {\n    width: 100%;\n}\n\n.grid {\n    width: 100%;\n    display: grid;\n    justify-content: center;\n    align-items: flex-start;\n    \n    grid-template-rows: auto;\n}\n\n@media (max-width: 480px) {\n    .grid {\n        grid-template-columns: 1fr;\n        grid-gap: 5px;\n    }\n}\n\n@media (max-width: 1100px) and (min-width: 480px) {\n    .grid {\n        grid-template-columns: repeat(auto-fit, min(300px, 90vw));\n        grid-gap: 10px;\n    }\n}\n\n@media (min-width: 1100px) {\n    .grid {\n        grid-template-columns: repeat(auto-fit, min(400px, 90vw));\n        grid-gap: 15px;\n    }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": `ResearchPreview_parent__lVfYN`,
	"grid": `ResearchPreview_grid__PN4ZK`
};
export default ___CSS_LOADER_EXPORT___;
