// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-element {
  width: min(320px, 80vw);
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: gray;
}

.service-element h2 {
  font-size: min(30px, 6vw);
}

.service-element h3 {
  font-size: min(18px, 3.6vw);;
}

.service-image {
  height: min(240px, 50vw);
  width: min(240px, 50vw);
  border-radius: 10%;
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  background-color: white;
  padding: 10px;
}

.service-title {
  margin: 10px 0 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceElement/ServiceElement.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".service-element {\n  width: min(320px, 80vw);\n  margin-top: 30px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  color: gray;\n}\n\n.service-element h2 {\n  font-size: min(30px, 6vw);\n}\n\n.service-element h3 {\n  font-size: min(18px, 3.6vw);;\n}\n\n.service-image {\n  height: min(240px, 50vw);\n  width: min(240px, 50vw);\n  border-radius: 10%;\n  border-color: lightgray;\n  border-style: solid;\n  border-width: 1px;\n  background-color: white;\n  padding: 10px;\n}\n\n.service-title {\n  margin: 10px 0 5px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
