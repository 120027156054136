// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paragraph {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: min(1400px, 90vw);
  margin-bottom: 35px;
  line-height: 1.5;
  max-width: 100%;
}

.paragraph a {
  text-decoration: underline;
}

.links li {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .paragraph h1 {
    font-size: 1.5rem;
  }

  .paragraph h2 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .paragraph h1 {
    font-size: 1.35rem;
  }

  .paragraph h2 {
    font-size: 1.125rem;
  }
}

.paragraph ol {
  margin: 0;
}

.paragraph ul {
  margin: 0;
}

.paragraph hr {
  height: 0px;
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/Paragraph/Paragraph.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,wBAAwB;EACxB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".paragraph {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: min(1400px, 90vw);\n  margin-bottom: 35px;\n  line-height: 1.5;\n  max-width: 100%;\n}\n\n.paragraph a {\n  text-decoration: underline;\n}\n\n.links li {\n  margin-top: 10px;\n}\n\n@media screen and (min-width: 768px) {\n  .paragraph h1 {\n    font-size: 1.5rem;\n  }\n\n  .paragraph h2 {\n    font-size: 1.25rem;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .paragraph h1 {\n    font-size: 1.35rem;\n  }\n\n  .paragraph h2 {\n    font-size: 1.125rem;\n  }\n}\n\n.paragraph ol {\n  margin: 0;\n}\n\n.paragraph ul {\n  margin: 0;\n}\n\n.paragraph hr {\n  height: 0px;\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
