// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 1300px) {
  .page-title {
    display: flex;
    /* min-height: 300px; */
    width: 100%;
    flex-direction: column;
    padding-top: 100px;
    align-items: center;
  }

  .page-title h1 {
    font-size: 40px;
    margin-top: 100px;
  }

  .page-title h2 {
    margin-top: 20px;
    width: min(1600px, 80vw);
    color: #474747;
    font-size: 21px;
    text-align: center;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1300px) {
  .page-title {
    display: flex;
    /* min-height: 200px; */
    width: 100%;
    flex-direction: column;
    padding-top: 150px;
    align-items: center;
  }

  .page-title h1 {
    font-size: 30px;
    margin-top: 80px;
  }

  .page-title h2 {
    margin-top: 15px;
    width: min(1600px, 80vw);
    color: #474747;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PageTitle/PageTitle.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,wBAAwB;IACxB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,wBAAwB;IACxB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":["@media screen and (min-width: 1300px) {\n  .page-title {\n    display: flex;\n    /* min-height: 300px; */\n    width: 100%;\n    flex-direction: column;\n    padding-top: 100px;\n    align-items: center;\n  }\n\n  .page-title h1 {\n    font-size: 40px;\n    margin-top: 100px;\n  }\n\n  .page-title h2 {\n    margin-top: 20px;\n    width: min(1600px, 80vw);\n    color: #474747;\n    font-size: 21px;\n    text-align: center;\n    margin-bottom: 30px;\n  }\n}\n\n@media screen and (max-width: 1300px) {\n  .page-title {\n    display: flex;\n    /* min-height: 200px; */\n    width: 100%;\n    flex-direction: column;\n    padding-top: 150px;\n    align-items: center;\n  }\n\n  .page-title h1 {\n    font-size: 30px;\n    margin-top: 80px;\n  }\n\n  .page-title h2 {\n    margin-top: 15px;\n    width: min(1600px, 80vw);\n    color: #474747;\n    font-size: 16px;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
