// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.particles {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  /* height: 900px; */
  height: 100%;
  z-index: -5;
}`, "",{"version":3,"sources":["webpack://./src/components/Background/Background.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,MAAM;EACN,SAAS;EACT,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".particles {\n  display: flex;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  width: 100%;\n  /* height: 900px; */\n  height: 100%;\n  z-index: -5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
