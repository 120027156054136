// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 500px) and (max-width: 1100px) {
    .CenteredGrid_iconParent__HznQ7 {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 500px) {
    .CenteredGrid_iconParent__HznQ7 {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 1100px) {
    .CenteredGrid_iconParent__HznQ7 {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1100px) {
    .CenteredGrid_iconContainer__G6bAJ {
        width: 100%;
        padding: 50px;
    }
}

@media (max-width: 1100px) {
    .CenteredGrid_iconContainer__G6bAJ {
        padding: 20px;
    }
}

.CenteredGrid_iconContainer__G6bAJ {
    flex-grow: 1;
    box-sizing: border-box;
}

.CenteredGrid_iconParent__HznQ7 {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: flex-start;
    grid-template-rows: auto;
    grid-gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/CenteredGrid/CenteredGrid.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,8BAA8B;IAClC;AACJ;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,8BAA8B;IAClC;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,wBAAwB;IACxB,cAAc;AAClB","sourcesContent":["@media (min-width: 500px) and (max-width: 1100px) {\n    .iconParent {\n        grid-template-columns: 1fr 1fr;\n    }\n}\n\n@media (max-width: 500px) {\n    .iconParent {\n        grid-template-columns: 1fr;\n    }\n}\n\n@media (min-width: 1100px) {\n    .iconParent {\n        grid-template-columns: 1fr 1fr;\n    }\n}\n\n@media (min-width: 1100px) {\n    .iconContainer {\n        width: 100%;\n        padding: 50px;\n    }\n}\n\n@media (max-width: 1100px) {\n    .iconContainer {\n        padding: 20px;\n    }\n}\n\n.iconContainer {\n    flex-grow: 1;\n    box-sizing: border-box;\n}\n\n.iconParent {\n    width: 100%;\n    display: grid;\n    justify-content: center;\n    align-items: flex-start;\n    grid-template-rows: auto;\n    grid-gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconParent": `CenteredGrid_iconParent__HznQ7`,
	"iconContainer": `CenteredGrid_iconContainer__G6bAJ`
};
export default ___CSS_LOADER_EXPORT___;
