// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Research_intro__iw67Z {
  display: flex;
  flex-direction: column;
  margin: 20px;
  max-width: 100%;
  text-align: center;
}

@media (min-width: 1100px) {
  .Research_iconContainer__JS47O {
    width: 100%;
  }
  .Research_introLeft__OZsbx, .Research_iconContainer__JS47O {
    margin: 50px;
  }
  .Research_iconParent__6Ttdk {
    grid-template-columns: 1fr 1fr
  }
}

@media (min-width: 500px) and (max-width: 1100px) {
  .Research_iconParent__6Ttdk {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 500px) {
  .Research_iconParent__6Ttdk {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1100px) {
  .Research_intro__iw67Z {
    margin: 20px;
  }
  .Research_iconParent__6Ttdk {
    grid-template-columns: 1fr 1fr;
  }
}

.Research_introTitle__t2uhx {
  font-size: 2;
  text-align: center;
  margin-bottom: 10px;
}

.Research_iconContainer__JS47O {
  flex-grow: 1;
}

.Research_iconParent__6Ttdk {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-rows: auto;
  grid-gap: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Research/Research.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,YAAY;EACd;EACA;IACE;EACF;AACF;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,wBAAwB;EACxB,cAAc;AAChB","sourcesContent":[".intro {\n  display: flex;\n  flex-direction: column;\n  margin: 20px;\n  max-width: 100%;\n  text-align: center;\n}\n\n@media (min-width: 1100px) {\n  .iconContainer {\n    width: 100%;\n  }\n  .introLeft, .iconContainer {\n    margin: 50px;\n  }\n  .iconParent {\n    grid-template-columns: 1fr 1fr\n  }\n}\n\n@media (min-width: 500px) and (max-width: 1100px) {\n  .iconParent {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n\n@media (max-width: 500px) {\n  .iconParent {\n    grid-template-columns: 1fr;\n  }\n}\n\n@media (max-width: 1100px) {\n  .intro {\n    margin: 20px;\n  }\n  .iconParent {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n\n.introTitle {\n  font-size: 2;\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.iconContainer {\n  flex-grow: 1;\n}\n\n.iconParent {\n  width: 100%;\n  display: grid;\n  justify-content: center;\n  align-items: flex-start;\n  grid-template-rows: auto;\n  grid-gap: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intro": `Research_intro__iw67Z`,
	"iconContainer": `Research_iconContainer__JS47O`,
	"introLeft": `Research_introLeft__OZsbx`,
	"iconParent": `Research_iconParent__6Ttdk`,
	"introTitle": `Research_introTitle__t2uhx`
};
export default ___CSS_LOADER_EXPORT___;
