// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchIntroIcon_container__oxgLR {
    display: flex;
    flex-direction: column;
    max-width: 375px;
}`, "",{"version":3,"sources":["webpack://./src/components/ResearchPreview/ResearchIntroIcon/ResearchIntroIcon.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    max-width: 375px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ResearchIntroIcon_container__oxgLR`
};
export default ___CSS_LOADER_EXPORT___;
