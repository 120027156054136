// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-blur {
  display: flex;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  width: 100%;
  justify-content: center;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  border-radius: 20px 20px 0 0;
}

.event-block {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
}

.event-block h1 {
  font-size: min(40px, 8vw);
}

.event-month {
  margin-top: 35px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Events/Events.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAAe;EAAf,sBAAe;EAAf,eAAe;EACf,WAAW;EACX,uBAAuB;EACvB,kCAA0B;UAA1B,0BAA0B;EAC1B,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".event-blur {\n  display: flex;\n  height: stretch;\n  width: 100%;\n  justify-content: center;\n  backdrop-filter: blur(7px);\n  border-radius: 20px 20px 0 0;\n}\n\n.event-block {\n  display: flex;\n  flex-direction: column;\n  padding: 25px 0;\n}\n\n.event-block h1 {\n  font-size: min(40px, 8vw);\n}\n\n.event-month {\n  margin-top: 35px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
