// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-blur {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  border-radius: 20px 20px 0 0;
  padding-bottom: 35px;
}


@media screen and (max-width: 1300px) {
  .about-blur {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1300px) {
  .about-blur {
    margin-top: 0;
  }
}

.about-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/About/About.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,kCAA0B;UAA1B,0BAA0B;EAC1B,4BAA4B;EAC5B,oBAAoB;AACtB;;;AAGA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb","sourcesContent":[".about-blur {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  backdrop-filter: blur(7px);\n  border-radius: 20px 20px 0 0;\n  padding-bottom: 35px;\n}\n\n\n@media screen and (max-width: 1300px) {\n  .about-blur {\n    margin-top: 30px;\n  }\n}\n\n@media screen and (min-width: 1300px) {\n  .about-blur {\n    margin-top: 0;\n  }\n}\n\n.about-block {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
