// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchArticlesPreview_parent__Q985z {
    width: 100%;
    background-color: var(--primary_background);
    padding: 50px 20px;
    box-sizing: border-box;
}

.ResearchArticlesPreview_text__vKvIf {
    text-align: center;
    margin-bottom: 20px;
}

.ResearchArticlesPreview_grid__mqH1C {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: flex-start;
    grid-template-rows: auto;
}

@media (max-width: 480px) {
    .ResearchArticlesPreview_grid__mqH1C {
        grid-template-columns: 1fr;
        grid-gap: 5px;
    }
    /* .parent {
        padding: 0 30px;
    } */
}

@media (max-width: 1100px) and (min-width: 480px) {
    .ResearchArticlesPreview_grid__mqH1C {
        grid-template-columns: repeat(auto-fit, min(400px, 90vw));
        grid-gap: 10px;
    }
    /* .parent {
        padding: 0 10%;
    } */
}

@media (min-width: 1100px) {
    .ResearchArticlesPreview_grid__mqH1C {
        grid-template-columns: repeat(auto-fit, min(400px, 90vw));
        grid-gap: 15px;
    }
    /* .parent {
        padding: 0 15%;
    } */
} `, "",{"version":3,"sources":["webpack://./src/components/ResearchArticlesPreview/ResearchArticlesPreview.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2CAA2C;IAC3C,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI;QACI,0BAA0B;QAC1B,aAAa;IACjB;IACA;;OAEG;AACP;;AAEA;IACI;QACI,yDAAyD;QACzD,cAAc;IAClB;IACA;;OAEG;AACP;;AAEA;IACI;QACI,yDAAyD;QACzD,cAAc;IAClB;IACA;;OAEG;AACP","sourcesContent":[".parent {\n    width: 100%;\n    background-color: var(--primary_background);\n    padding: 50px 20px;\n    box-sizing: border-box;\n}\n\n.text {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.grid {\n    width: 100%;\n    display: grid;\n    justify-content: center;\n    align-items: flex-start;\n    grid-template-rows: auto;\n}\n\n@media (max-width: 480px) {\n    .grid {\n        grid-template-columns: 1fr;\n        grid-gap: 5px;\n    }\n    /* .parent {\n        padding: 0 30px;\n    } */\n}\n\n@media (max-width: 1100px) and (min-width: 480px) {\n    .grid {\n        grid-template-columns: repeat(auto-fit, min(400px, 90vw));\n        grid-gap: 10px;\n    }\n    /* .parent {\n        padding: 0 10%;\n    } */\n}\n\n@media (min-width: 1100px) {\n    .grid {\n        grid-template-columns: repeat(auto-fit, min(400px, 90vw));\n        grid-gap: 15px;\n    }\n    /* .parent {\n        padding: 0 15%;\n    } */\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": `ResearchArticlesPreview_parent__Q985z`,
	"text": `ResearchArticlesPreview_text__vKvIf`,
	"grid": `ResearchArticlesPreview_grid__mqH1C`
};
export default ___CSS_LOADER_EXPORT___;
