// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-container {
  background-color: var(--primary_background);
  width: 100%;
  box-sizing: border-box;
}

.home-event-title, .home-event-description {
  text-align: center;
  align-self: center;
  padding: 20px 30%;
  color: black;
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  height: 100%;
  width: 70%;
  padding-bottom: 35px;
}

.event {
  color: rgb(84, 84, 84);
  text-align: center;
  padding: 14px;
}

.event h2 {
  font-size: min(27px, 50px);
  color: black;
  margin: 25px;
}

@media screen and (max-width: 760px) {
  .event-grid {
    display: flex;
    flex-direction: column;
    overflow: visible;
    position: relative;
  }
  .event-container {
    padding: 15px;
  }
}

@media (min-width: 760px) {
  .event-container {
    padding: 15px 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/HomeModules/HomeEvent/HomeEvent.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,cAAc;EACd,YAAY;EACZ,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,0BAA0B;EAC1B,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".event-container {\n  background-color: var(--primary_background);\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.home-event-title, .home-event-description {\n  text-align: center;\n  align-self: center;\n  padding: 20px 30%;\n  color: black;\n}\n\n.event-grid {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  margin: 0 auto;\n  height: 100%;\n  width: 70%;\n  padding-bottom: 35px;\n}\n\n.event {\n  color: rgb(84, 84, 84);\n  text-align: center;\n  padding: 14px;\n}\n\n.event h2 {\n  font-size: min(27px, 50px);\n  color: black;\n  margin: 25px;\n}\n\n@media screen and (max-width: 760px) {\n  .event-grid {\n    display: flex;\n    flex-direction: column;\n    overflow: visible;\n    position: relative;\n  }\n  .event-container {\n    padding: 15px;\n  }\n}\n\n@media (min-width: 760px) {\n  .event-container {\n    padding: 15px 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
