// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-words {
    margin: 6% auto;
    width: min(1500px, 80%);
    justify-content: space-between;
    column-count: 3;
    column-gap: 20px;
    max-width: 1200px;
    color: grey;
  }
  
  .landing-words-column h1 {
    font-size: min(100px, 8vw);
    text-align: center;
    align-self: center;
  }
  
  .landing-words-column p {
    font-size: min(27px, 8vw);
    text-align: center;
  }
  
  @media screen and (max-width: 1650px) {
    .landing-words {
      flex-direction: column;
      align-items: center;
      gap: 60px
    }
  }
  
  @media screen and (max-width: 1000px) {
    .landing-words {
      display: flex;
      width: -webkit-fill-available;
      width: fill;
      flex-direction: column;
      align-items: center;
    }
  
    .landing-words-column {
      margin-bottom: 20px;
    }
  
    .landing-words-column p {
      margin-top: 3px;
      width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/HomeModules/HomeStats/HomeStats.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uBAAuB;IACvB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,0BAA0B;IAC1B,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE;MACE,sBAAsB;MACtB,mBAAmB;MACnB;IACF;EACF;;EAEA;IACE;MACE,aAAa;MACb,6BAAW;MAAX,WAAW;MACX,sBAAsB;MACtB,mBAAmB;IACrB;;IAEA;MACE,mBAAmB;IACrB;;IAEA;MACE,eAAe;MACf,WAAW;IACb;EACF","sourcesContent":[".landing-words {\n    margin: 6% auto;\n    width: min(1500px, 80%);\n    justify-content: space-between;\n    column-count: 3;\n    column-gap: 20px;\n    max-width: 1200px;\n    color: grey;\n  }\n  \n  .landing-words-column h1 {\n    font-size: min(100px, 8vw);\n    text-align: center;\n    align-self: center;\n  }\n  \n  .landing-words-column p {\n    font-size: min(27px, 8vw);\n    text-align: center;\n  }\n  \n  @media screen and (max-width: 1650px) {\n    .landing-words {\n      flex-direction: column;\n      align-items: center;\n      gap: 60px\n    }\n  }\n  \n  @media screen and (max-width: 1000px) {\n    .landing-words {\n      display: flex;\n      width: fill;\n      flex-direction: column;\n      align-items: center;\n    }\n  \n    .landing-words-column {\n      margin-bottom: 20px;\n    }\n  \n    .landing-words-column p {\n      margin-top: 3px;\n      width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
