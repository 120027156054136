// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_body__AOzHb {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    box-sizing: border-box;
}

@media (min-width: 1100px) {
    .Layout_body__AOzHb {
        padding: 10vh 10vw;
    }
}

@media (max-width: 480px) {
    .Layout_body__AOzHb {
        padding: 25px 10px;
    }
}

@media (min-width: 480px) and (max-width: 768px)  {
    .Layout_body__AOzHb {
        padding: 25px 10px;
    }
}

@media (min-width: 768px) and (max-width: 1100px) {
    .Layout_body__AOzHb {
        padding: 5vh 10vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".body {\n    display: flex;\n    max-width: 100%;\n    flex-direction: column;\n    box-sizing: border-box;\n}\n\n@media (min-width: 1100px) {\n    .body {\n        padding: 10vh 10vw;\n    }\n}\n\n@media (max-width: 480px) {\n    .body {\n        padding: 25px 10px;\n    }\n}\n\n@media (min-width: 480px) and (max-width: 768px)  {\n    .body {\n        padding: 25px 10px;\n    }\n}\n\n@media (min-width: 768px) and (max-width: 1100px) {\n    .body {\n        padding: 5vh 10vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `Layout_body__AOzHb`
};
export default ___CSS_LOADER_EXPORT___;
