// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-padding {
    height: 100px;
}

@media screen and (min-width: 1300px) {
    .page-padding {
      display: flex;
      min-height: 100px;
      width: 100%;
      flex-direction: column;
      padding-top: 100px;
      align-items: center;
    }
  }
  
  @media screen and (max-width: 1300px) {
    .page-padding {
      display: flex;
      min-height: 200px;
      width: 100%;
      flex-direction: column;
      padding-top: 75px;
      align-items: center;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/PagePadding/PagePadding.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI;MACE,aAAa;MACb,iBAAiB;MACjB,WAAW;MACX,sBAAsB;MACtB,kBAAkB;MAClB,mBAAmB;IACrB;EACF;;EAEA;IACE;MACE,aAAa;MACb,iBAAiB;MACjB,WAAW;MACX,sBAAsB;MACtB,iBAAiB;MACjB,mBAAmB;IACrB;EACF","sourcesContent":[".page-padding {\n    height: 100px;\n}\n\n@media screen and (min-width: 1300px) {\n    .page-padding {\n      display: flex;\n      min-height: 100px;\n      width: 100%;\n      flex-direction: column;\n      padding-top: 100px;\n      align-items: center;\n    }\n  }\n  \n  @media screen and (max-width: 1300px) {\n    .page-padding {\n      display: flex;\n      min-height: 200px;\n      width: 100%;\n      flex-direction: column;\n      padding-top: 75px;\n      align-items: center;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
