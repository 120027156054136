// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Consults_intro__jnGS2 {
  display: flex;
  flex-direction: column;
  margin: 70px;
  max-width: 100%;
  text-align: center;
}

.Consults_workflow__Hlru3 {
  display: flex;
  flex-direction: column;
  margin: 70px;
  max-width: 100%;
}

.Consults_workflowTitle__tpN\\+j {
  text-align: center;
}

.Consults_clientHeader__i5UwU {
  color: #6070FF;
  text-align: center;
}


.Consults_clients__uloW1 {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
}

@media (min-width: 1100px) {
  .Consults_iconContainer__CFDou {
    width: 100%;
  }

  .Consults_iconContainer__CFDou {
    margin: 50px 0;
  }

  .Consults_iconParent__GltSX {
    grid-template-columns: repeat(auto-fit, 375px);
  }
}

@media (max-width: 1100px) {
  .Consults_intro__jnGS2,
  .Consults_iconContainer__CFDou {
    margin: 20px;
  }

  .Consults_iconParent__GltSX {
    grid-template-columns: repeat(auto-fit, min(250px, 90vw));
  }
}

.Consults_introTitle__00WAa {
  font-size: 2;
}

.Consults_iconContainer__CFDou {
  flex-grow: 1;
}

.Consults_iconParent__GltSX {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-rows: auto;
  grid-gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Consults/Consults.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;;AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,8CAA8C;EAChD;AACF;;AAEA;EACE;;IAEE,YAAY;EACd;;EAEA;IACE,yDAAyD;EAC3D;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,wBAAwB;EACxB,cAAc;AAChB","sourcesContent":[".intro {\n  display: flex;\n  flex-direction: column;\n  margin: 70px;\n  max-width: 100%;\n  text-align: center;\n}\n\n.workflow {\n  display: flex;\n  flex-direction: column;\n  margin: 70px;\n  max-width: 100%;\n}\n\n.workflowTitle {\n  text-align: center;\n}\n\n.clientHeader {\n  color: #6070FF;\n  text-align: center;\n}\n\n\n.clients {\n  display: flex;\n  flex-direction: column;\n  place-items: center;\n  width: 100%;\n}\n\n@media (min-width: 1100px) {\n  .iconContainer {\n    width: 100%;\n  }\n\n  .iconContainer {\n    margin: 50px 0;\n  }\n\n  .iconParent {\n    grid-template-columns: repeat(auto-fit, 375px);\n  }\n}\n\n@media (max-width: 1100px) {\n  .intro,\n  .iconContainer {\n    margin: 20px;\n  }\n\n  .iconParent {\n    grid-template-columns: repeat(auto-fit, min(250px, 90vw));\n  }\n}\n\n.introTitle {\n  font-size: 2;\n}\n\n.iconContainer {\n  flex-grow: 1;\n}\n\n.iconParent {\n  width: 100%;\n  display: grid;\n  justify-content: center;\n  align-items: flex-start;\n  grid-template-rows: auto;\n  grid-gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intro": `Consults_intro__jnGS2`,
	"workflow": `Consults_workflow__Hlru3`,
	"workflowTitle": `Consults_workflowTitle__tpN+j`,
	"clientHeader": `Consults_clientHeader__i5UwU`,
	"clients": `Consults_clients__uloW1`,
	"iconContainer": `Consults_iconContainer__CFDou`,
	"iconParent": `Consults_iconParent__GltSX`,
	"introTitle": `Consults_introTitle__00WAa`
};
export default ___CSS_LOADER_EXPORT___;
